import {CustomFormField, IUser} from "../../../types/interfaces";
import {ISurveyWithId} from "../SurveyDataController";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../../types/enums";
import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {EditObject} from "../../../Shared/Components/EditObject";

export function AddNewSurveyModal(props: { usersList: IUser[], onSave:  ((survey: ISurveyWithId) => Promise<void>), onCancel: (() => void) })  {

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "caseNumber",
            label: "Case Number"
        },
        {
            kind: CustomFormFieldTypes.SELECT_DROPDOWN,
            size: CustomFormFieldSizes.FULL,
            key: "assigned_user_id",
            label: "Assigned User Id",
            options: [
                { label: "Un-assigned", value: null },
                ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
            ]
        },
    ];
    const [survey, setSurvey] = React.useState<ISurveyWithId>({
        caseNumber: "",
        content: [],
        locations: [],
        // selected technician
    });

    const [isSaving, setIsSaving] = React.useState(false);

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Add New Survey
            </Typography>

            <EditObject item={survey} setItem={setSurvey} form={form} columns={5}></EditObject>

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={async () => {
                setIsSaving(true)
                await props.onSave(survey);
                setIsSaving(false);
            }} disabled={ survey === undefined || survey['caseNumber'] === "" || isSaving }>CREATE</Button>

        </Box>
    );
}
