import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {ICase, ICompany, IProject, ISurvey, IUser} from "../../types/interfaces";
import {ISurveyWithId, SurveyDataController} from "../Surveys/SurveyDataController";
import {DataGrid, GridCellParams, GridColDef, GridRowModel, GridValidRowModel} from "@mui/x-data-grid";
import {UserRole} from "../../types/enums";
import {Button, Modal, Stack, Tooltip, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {UserDataController} from "../Users/UserDataController";
import {useLocalStorage} from "../../Hooks/useLocalStorage";
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faPlus, faRotateRight} from "@fortawesome/free-solid-svg-icons";
import {CaseDataController, ICaseWithId} from "./CaseDataController";
import {AddNewCaseModal} from "./AddNewCaseModal";

function CaseManagementView() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const [usersList, setUserList] = React.useState<IUser[]>([])

    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = await new CaseDataController(userToken).getAll();
        setList(all);

        let allUsers = await new UserDataController(userToken).getAll();
        setUserList(allUsers);
    },[userToken]);

    React.useEffect(() => {
        UpdateList().then();
    }, [userToken]);

    const columns = React.useMemo(() => {
        let columns: GridColDef[] = []

        if (user?.role === UserRole.ADMIN || user?.role === UserRole.MANAGER || user?.role === UserRole.USER_MANAGER) {
            columns.push({
                field: 'open_btn',
                headerName: '',
                sortable: false,
                width: 100,
                renderCell: (params: GridCellParams) => (
                    <Tooltip title={"Open Survey"} arrow={true}>
                        <Button variant="outlined" color="primary" fullWidth={true} component={RouterLink}
                                to={`/dashboard/cases/${params.id}`}>
                            Open
                        </Button>
                    </Tooltip>
                )
            });

        }

        columns.push({ field: 'caseNumber', headerName: 'Case Number', width: 300, resizable: true, editable: false,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <Typography sx={{ padding: "3px", width: '100%' }}>{params.value}</Typography>
                </Tooltip>
            ),
        });

        if (user?.role !== UserRole.CLIENT) {

            columns.push({ field: 'company_project', headerName: 'Company / Project', width: 300, editable: false,
                valueGetter: (params) => {
                    let survey = params.row as ISurvey;
                    if (survey !== undefined && survey.project !== null && survey.project !== undefined) {
                        let project = survey.project as IProject;
                        if (project !== undefined && project.company !== null && project.company !== undefined) {
                            return (project.company as ICompany).name + " / " + project.name
                        }
                    }
                    return "";
                }
            });

            columns.push({ field: 'assigned_user_id', headerName: 'Assigned User', width: 300, editable: false, valueGetter: params => {
                    try {
                        let survey = params.row as ISurvey;
                        if (survey.assigned_user_id !== undefined){

                            let user = usersList.find((u) => u._id === survey.assigned_user_id);
                            if (user !== undefined) {
                                return user.name;
                            }
                            else {
                                return "Unknown"
                            }
                        }
                        else {
                            return "Unassigned"
                        }
                    }
                    catch (e) {
                        return "Unassigned"
                    }

                } });
            columns.push({ field: 'coordinator_user_id', headerName: 'Coordinator', width: 300, editable: false, valueGetter: params => {
                try {
                    let survey = params.row as ISurvey;
                    if (survey.coordinator_user_id !== undefined){
                        let user = usersList.find((u) => u._id === survey.coordinator_user_id);
                        if (user !== undefined) {
                            return user.name;
                        }
                        else {
                            return "Unknown"
                        }
                    }
                    else {
                        return "Unassigned"
                    }
                }
                catch (e) {
                    return "Unassigned"
                }

            } });

            columns.push({
                field: 'createdAt', headerName: 'Created At', width: 200, editable: false,
                valueFormatter: params => {
                    try {
                        if (params.value === undefined || params.value === null) {
                            return "";
                        }
                        let date = new Date(params.value as string);
                        return date.toLocaleString();
                    } catch (e) {
                        // console.log(e);
                        return "";
                    }
                }
            });
        }



        if (user?.role === UserRole.CLIENT) {
            columns.push({field: 'locationName', headerName: 'Location Name', width: 300, editable: false});
            columns.push({field: 'address', headerName: 'Site Address', width: 300, editable: false});
        }




        if (user?.role === UserRole.ADMIN) {
            columns.push({ field: '_id', headerName: 'ID', width: 310, editable: false,
                renderCell: (params) => (
                    <Stack direction={"row"} sx={{ width: '100%' }}>
                        <Button variant="outlined" color="primary" size={"small"} fullWidth={false} onClick={async () => {
                            let item = params.row as ISurvey & { _id: string };
                            try {
                                await navigator.clipboard.writeText(item._id);
                                console.log('Text copied to clipboard');
                            } catch (err) {
                                console.error('Failed to copy: ', err);
                            }
                        }}>Copy</Button>
                        <Typography sx={{ padding: "3px" }}>&nbsp;{params.value}</Typography>
                    </Stack>
                ),
            });
        }
        else if (user?.role !== UserRole.CLIENT) {
            columns.push({ field: '_id', headerName: 'ID', width: 400, editable: false });
        }

        return columns;
    }, [user, userToken, UpdateList, usersList])

    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        // Handle the selection
    };

    const [archiveActive, setArchiveActive] = React.useState<boolean>(false);

    const [list, setList] = React.useState<ICaseWithId[]>([]);
    const [showAddNewCaseModal, setShowAddNewCaseModal] = React.useState<boolean>(false);

    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) => {
            if (userToken === null || userToken === undefined) {
                return;
            }
            return await new SurveyDataController(userToken).update(newRow._id, newRow as ISurveyWithId);
        },
        [userToken],
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log('handleProcessRowUpdateError', error)
        alert(error.message);
    }, []);



    const [showDateFilter, setShowDateFilter] = React.useState<boolean>(false);

    const [searchText, setSearchText] = useLocalStorage("SurveyManagementView.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];

    const customButtonsMemo = React.useMemo(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [
            {
                label: "Date Filter",
                action: () => {
                    setShowDateFilter(!showDateFilter);
                },
                disabled: true,
                icon: <FontAwesomeIcon icon={faCalendar} />
            },
        ];


        if (user?.role !== UserRole.CLIENT) {
            buttons.push({
                label: "Create",
                action: () => {
                    setShowAddNewCaseModal(true);
                },
                icon: <FontAwesomeIcon icon={faPlus} />
            });

            buttons.push({
                label: "Reload",
                action: () => {
                    UpdateList().then();
                },
                icon: <FontAwesomeIcon icon={faRotateRight} />
            })
        }

        return buttons;
    }, [archiveActive, selectionModel, userToken, list]);


    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Cases"}
                                  searchText={{text: searchText, setText: setSearchText, label: user?.role === UserRole.CLIENT ? "Search (Case Number)": "Search (Case Number, Assigned User, Project Name)"}}
                                  customButtons={customButtonsMemo}
            >

                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={[100]}
                    disableRowSelectionOnClick
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={handleSelection}
                    checkboxSelection={user?.role === UserRole.ADMIN}
                />

                <Modal
                    open={showAddNewCaseModal}
                    onClose={() => { setShowAddNewCaseModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {<>
                        <AddNewCaseModal
                            usersList={usersList}
                            onSave={async (caseItem: ICase) => {
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                await new CaseDataController(userToken).create(caseItem);
                                let all = await new CaseDataController(userToken).getAll();
                                setList(all);
                                setShowAddNewCaseModal(false);
                            }}
                            onCancel={() => { setShowAddNewCaseModal(false) }}
                        />
                    </>}
                </Modal>

            </AuthenticatedLayout>
        </div>
    );
}

export default CaseManagementView;
