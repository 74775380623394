import {CustomFormField, ICase, IUser} from "../../types/interfaces";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../types/enums";
import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {EditObject} from "../../Shared/Components/EditObject";


export function AddNewCaseModal(props: { usersList: IUser[], onSave: ((caseItem: ICase) => void), onCancel: (() => void) })  {

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "caseNumber",
            label: "Case Number"
        },
        {
            kind: CustomFormFieldTypes.SELECT_DROPDOWN,
            size: CustomFormFieldSizes.FULL,
            key: "assigned_user_id",
            label: "Assigned User Id",
            options: [
                { label: "Un-assigned", value: null },
                ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
            ]
        },
    ];
    const [caseItem, setCaseItem] = React.useState<ICase>({
        caseNumber: "",
        attachedMaterials: [],
    });

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Add New Case
            </Typography>

            <EditObject item={caseItem} setItem={setCaseItem} form={form} columns={5}></EditObject>

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                props.onSave(caseItem);
            }} disabled={ caseItem === undefined || caseItem['caseNumber'] === "" }>CREATE</Button>

        </Box>
    );
}


