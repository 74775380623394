import {CustomFormField} from "../../types/interfaces";
import {Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React from "react";

export function EditSelectDropdown<T>(props: { item: T, setItem: any, field: CustomFormField }) {
    const currentValue = props.item[props.field.key as keyof T];
    return (<>
        {/*<pre>{ JSON.stringify( props.item[props.field.key as keyof T] ) }</pre>*/}
        <FormControl fullWidth>
            {/*<InputLabel shrink={true} id={"_label_" + props.field.key}>{props.field.label}</InputLabel>*/}

            <Autocomplete
                disablePortal
                options={props.field.options || []}
                // sx={{ width: 300 }}
                fullWidth={true}
                value={props.field.options?.find(
                    (option) => JSON.stringify(option.value) === JSON.stringify(currentValue)
                ) || null} // Match current value
                onChange={(_, newValue) => {
                    const updatedItem = { ...props.item };
                    updatedItem[props.field.key as keyof T] = newValue?.value ?? null;
                    props.setItem(updatedItem);
                }}
                disabled={props.field.readonly}
                // value={ JSON.stringify(props.item[props.field.key as keyof T] ) }
                // InputLabelProps={{ shrink: true }}
                renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: (props.field.options?.find(
                        (option) => JSON.stringify(option.value) === JSON.stringify(currentValue)
                    ) || null) !== null }} label={props.field.label} />}
            />

            {/*<Select*/}
            {/*    labelId={"_label_" + props.field.key}*/}
            {/*    id={"_input_" + props.field.key}*/}
            {/*    value={ JSON.stringify(props.item[props.field.key as keyof T] ) }*/}
            {/*    label={props.field.label}*/}
            {/*    onChange={(e)=>{*/}
            {/*        let newItem = {...props.item};*/}
            {/*        newItem[props.field.key as keyof T] = JSON.parse( e.target.value as unknown as T[keyof T] as string );*/}
            {/*        props.setItem(newItem);*/}
            {/*    }}*/}
            {/*    disabled={props.field.readonly}*/}
            {/*>*/}
            {/*    {Object.values(props.field.options || []).map((option, index) => {*/}
            {/*        //  as {value: any, label: string}*/}
            {/*        let opt = option as {value: any, label: string, selected?: boolean};*/}
            {/*        return <MenuItem key={opt.value || index} value={JSON.stringify(opt.value)}>{opt.label}</MenuItem>*/}
            {/*    })}*/}
            {/*</Select>*/}
        </FormControl>
    </>)
}
