import React, { ErrorInfo, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error, errorInfo: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log error to an error reporting service here
        console.error('Uncaught error:', error, errorInfo);
        this.setState({ errorInfo });
    }

    renderErrorDetails() {
        const { error, errorInfo } = this.state;
        // if (process.env.NODE_ENV === 'development') {
            return (
                <div>
                    <h2>Error Details</h2>
                    <p>{error?.toString()}</p>
                    <pre>{errorInfo?.componentStack}</pre>
                </div>
            );
        // }
        // In production, you might want to render a more user-friendly message or log these details instead
        // return <p>Sorry, something went wrong.</p>;
    }

    render() {
        if (this.state.hasError) {
            // Custom fallback UI
            return (
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <h1>Oops! Something went wrong.</h1>
                    {this.renderErrorDetails()}
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
