import {CustomFormField, ICompany, IProject, ISurvey, IUser} from "../../../types/interfaces";
import React from "react";
import {AuthContext} from "../../../Library/AuthContext";
import {CompanyDataController} from "../../Projects/CompanyDataController";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../../types/enums";
import {Box, Button, Typography} from "@mui/material";
import {EditObject} from "../../../Shared/Components/EditObject";

export function CloneSurveyModal(props: { usersList: IUser[], onSave: ((survey: ISurvey) => void), onCancel: (() => void), survey: ISurvey })  {

    // const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [survey, setSurvey] = React.useState<ISurvey>({
        caseNumber: "",
        assigned_user_id: null,
        content: props.survey.content,
        locations: props.survey.locations,
        coordinator_user_id: props.survey.coordinator_user_id,
        surveyName: props.survey.surveyName,
        company: props.survey.company,
        project: props.survey.project !== undefined && props.survey.project !== null ? (props.survey.project as IProject & {_id: string})._id: undefined,
        // companyName: props.survey.companyName,
        // projectName: props.survey.projectName,
        surveyOwner: props.survey.surveyOwner,
        conditionFlags: props.survey.conditionFlags,
        // selected technician
    });


    const [companies, setCompanies] = React.useState<ICompany[]>([]);
    const projectsMemo = React.useMemo(() => {
        if (companies === undefined || companies === null || companies.length === 0) {
            return [];
        }
        if (survey === null || survey === undefined || survey.company === null) {
            return [];
        }
        let projects = companies.find((company) => {
            return (company as ICompany & {_id: string})._id === survey.company;
        })?.projects as IProject[] ?? [];
        return projects.sort((a, b) => a.name.localeCompare(b.name));
    }, [companies, survey ]);

    const loadCompanyData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = await new CompanyDataController(userToken).getAll();
        setCompanies(all.sort((a, b) => a.name.localeCompare(b.name)));
    }, [userToken]); // Dependencies array


    React.useEffect(() => {

        loadCompanyData().then();
    }, [userToken, loadCompanyData]);


    const form = React.useMemo(() => {
        const form: CustomFormField[] = [
            {
                kind: CustomFormFieldTypes.TEXT,
                size: CustomFormFieldSizes.FULL,
                key: "caseNumber",
                label: "Case Number"
            },
            {
                kind: CustomFormFieldTypes.SELECT_DROPDOWN,
                size: CustomFormFieldSizes.FULL,
                key: "assigned_user_id",
                label: "Assigned User Id",
                options: [
                    { label: "Un-assigned", value: null },
                    ...props.usersList.map((opt: (IUser & {_id: string})) => {
                        let label = opt.name + " [" + opt.role + "]";
                        return { label: label, value: opt._id };
                    })
                ]
            },
            {
                kind: CustomFormFieldTypes.SELECT_DROPDOWN,
                size: CustomFormFieldSizes.FULL,
                key: "coordinator_user_id",
                label: "Coordinator User Id",
                options: [
                    { label: "Un-assigned", value: null },
                    ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
                ]
            },
            {
                kind: CustomFormFieldTypes.TEXT,
                size: CustomFormFieldSizes.FULL,
                key: "surveyName",
                label: "Survey Name"
            },
            {
                kind: CustomFormFieldTypes.SELECT,
                size: CustomFormFieldSizes.FULL,
                key: "company",
                label: "Company",
                options: [
                    { label: "Un-assigned", value: null },
                    ...companies.map((opt: (ICompany)) => { return { label: opt.name, value: (opt as ICompany & {_id: string})._id }; })
                ]
            },
            {
                kind: CustomFormFieldTypes.SELECT,
                size: CustomFormFieldSizes.FULL,
                key: "project",
                label: "Project",
                options: [
                    { label: "Un-assigned", value: null },
                    ...projectsMemo.map((opt: (IProject)) => { return { label: opt.name, value: (opt as IProject & {_id: string})._id }; })
                ],
                readonly: survey === undefined || survey.company === null
            },
            // {
            //     kind: CustomFormFieldTypes.TEXT,
            //     size: CustomFormFieldSizes.FULL,
            //     key: "companyName",
            //     label: "Company Name"
            // },
            // {
            //     kind: CustomFormFieldTypes.TEXT,
            //     size: CustomFormFieldSizes.FULL,
            //     key: "projectName",
            //     label: "Project Name"
            // },
            {
                kind: CustomFormFieldTypes.TEXT,
                size: CustomFormFieldSizes.FULL,
                key: "surveyOwner",
                label: "Survey Owner"
            },
        ];

        return form;
    }, [companies, projectsMemo, props.usersList, survey.company]);




    React.useEffect(() => {



        setSurvey({
            // caseNumber: props.survey.caseNumber,
            // assigned_user_id: props.survey.assigned_user_id,
            caseNumber: survey.caseNumber,
            assigned_user_id: (survey.assigned_user_id === undefined ? null : survey.assigned_user_id) ,




            content: props.survey.content,
            locations: props.survey.locations,
            coordinator_user_id: (props.survey.coordinator_user_id === undefined || props.survey.coordinator_user_id === null ? null : props.survey.coordinator_user_id._id),
            company: props.survey.company,
            // project: props.survey.project,
            project: props.survey.project !== undefined && props.survey.project !== null ? (props.survey.project as IProject & {_id: string})._id: undefined,
            // project: (props.survey.project as IProject & {_id: string})._id,
            // project: (props.survey.project as IProject & {_id: string})._id,
            surveyName: props.survey.surveyName,
            // companyName: props.survey.companyName,
            // projectName: props.survey.projectName,
            surveyOwner: props.survey.surveyOwner,
            conditionFlags: props.survey.conditionFlags,
            // selected technician
        });
    }, [props.usersList, props.survey, survey.assigned_user_id, survey.caseNumber]);



    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Clone Survey: {props.survey.caseNumber}
            </Typography>

            <EditObject item={survey} setItem={setSurvey} form={form} columns={5}></EditObject>

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                console.log(survey)
                props.onSave(survey);
            }} disabled={ survey === undefined || survey['caseNumber'] === "" }>CREATE</Button>

        </Box>
    );
}

