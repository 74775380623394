import {CustomFormField, ICase, ISurvey, IUser, SiteContactPhone} from "../../../types/interfaces";
import React from "react";
import {
    Box,
    Button, IconButton, InputAdornment,
    List,
    ListItem, ListItemIcon,
    ListItemText,
    ListSubheader,
    Modal,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";

export const LongFormCaseTextField = (props : { caseItem: ICase, setCase: (caseItem: ICase) => void, field: CustomFormField }) => {

    const [open, setOpen] = React.useState<boolean>(false);

    let key = props.field.key as keyof ICase;
    const [text, setText] = React.useState<string>((props.caseItem[key] as (string | undefined)) || "");

    return (<>
        <TextField
            label={props.field.label}
            id={props.field.label + "-readonly-text"}
            // sx={{ m: 1, width: '25ch' }}
            value={text}
            disabled={true}
            fullWidth={true}
            InputProps={{
                // input: {
                    endAdornment: <InputAdornment position="end">
                        <Button
                            type={"button"}
                            variant={"outlined"}
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faPencil} />
                        </Button>
                    </InputAdornment>,
                // },
            }}
        />
        {/*<Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {*/}
        {/*    setOpen(true)*/}
        {/*}}>Specific Instruction</Button>*/}
        <Modal open={open} onClose={() => {
            setOpen(false);
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '600px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    {props.field.label}
                </Typography>

                <Stack direction={'column'} spacing={2}>
                    <TextField
                        id={props.field.label + "-multiline"}
                        label={props.field.label}
                        multiline
                        rows={10}
                        value={text}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setText(event.target.value);
                        }}
                    />

                    <Button variant="outlined" color="primary" size={"small"} fullWidth={true} onClick={async () => {
                        // let user = params.row as IUser;
                        // try {
                        //     await navigator.clipboard.writeText(user.email_address);
                        //     console.log('Text copied to clipboard');
                        // } catch (err) {
                        //     console.error('Failed to copy: ', err);
                        // }



                        props.setCase({
                            ...props.caseItem,
                            [key]: text,
                        });
                        setOpen(false);

                    }}>Save</Button>
                </Stack>
            </Box>
        </Modal>
    </>);
}

export const V1_SpecificInstructionPassThru = (props : { caseItem: ICase, setCase: (caseItem: ICase) => void, field: CustomFormField }) => {

    const [open, setOpen] = React.useState<boolean>(false);

    const [text, setText] = React.useState<string>(props.caseItem.specificInstructions || "");
    const [text2, setText2] = React.useState<string>(props.caseItem.serviceDescription || "");

    return (<>
        {/*<TextField*/}
        {/*    label="With normal TextField"*/}
        {/*    id="outlined-start-adornment"*/}
        {/*    // sx={{ m: 1, width: '25ch' }}*/}
        {/*    disabled={true}*/}
        {/*    InputProps={{*/}
        {/*        // input: {*/}
        {/*            endAdornment: <InputAdornment position="end">*/}
        {/*                <Button>Edit</Button>*/}
        {/*            </InputAdornment>,*/}
        {/*        // },*/}
        {/*    }}*/}
        {/*/>*/}
        <Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {
            setOpen(true)
        }}>Specific Instruction</Button>
        <Modal open={open} onClose={() => {
            setOpen(false);
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '600px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Specific Instruction
                </Typography>

                <Stack direction={'column'} spacing={2}>
                    <TextField
                        id="specific-instruction-multiline"
                        label="Specific Instruction"
                        multiline
                        rows={10}
                        value={text}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setText(event.target.value);
                        }}
                    />

                    <TextField
                        id="service-description-multiline"
                        label="Service Description"
                        multiline
                        rows={10}
                        value={text2}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setText2(event.target.value);
                        }}
                    />


                    <Button variant="outlined" color="primary" size={"small"} fullWidth={true} onClick={async () => {
                        // let user = params.row as IUser;
                        // try {
                        //     await navigator.clipboard.writeText(user.email_address);
                        //     console.log('Text copied to clipboard');
                        // } catch (err) {
                        //     console.error('Failed to copy: ', err);
                        // }

                        props.setCase({
                            ...props.caseItem,
                            specificInstructions: text,
                            serviceDescription: text2
                        });
                        setOpen(false);

                    }}>Save</Button>
                </Stack>
            </Box>
        </Modal>
    </>);
}
