
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];


export enum UserRole {
    ADMIN = 'Admin',
    CLIENT = 'Client',
    TECHNICIAN = 'Technician',
    MANAGER = 'Manager',
    USER_MANAGER = 'User Manager',
}

export enum CustomFormFieldTypes {
    TEXT = "text",
    PHONE_NUMBER = "phone_number",
    EMAIL = "email",
    SELECT = "select",
    SELECT_DROPDOWN = "select_dropdown",
    TOGGLE = "toggle",
    DATE = "date",
    PASSWORD = "password",
    TEXTAREA = "textarea",
    // GRID = "GRID",
    PASSTHRU = "PASSTHRU",
    DATETIME = "datetime",
    DATETIMEWOZONE = "datetime_wo_zone",
    RADIO_GROUP = "radio_group",
}

export enum CustomFormFieldSizes {
    SMALL = "small",
    MEDIUM = "medium",
    HALF = "half",
    LARGE = "large",
    FULL = "full",
    ONE = "one",
    TWO = "two",
    THREE = "three",
}

export enum ReportType {
    Standard = "Standard",
    // Simplified = "Simplified",
    FullResolutionImages = "FullResolutionImages",
    Exception = "Exception",
    SpreadSheet = "SpreadSheet",
    CreatorReport = "CreatorReport"
}
